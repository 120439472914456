<template>
    <div class="content-box">
        <van-sticky>
            <title-bar2 title="联系商家阅读" />
        </van-sticky>
        <van-cell-group title="激活方法：请复制资料名称+手机号码发送给商家激活。">
            <van-cell title="手机号码：" :label="userPhone" />
            <van-cell title="资料名称：" :label="documentName" />
            <van-cell>
                <div class="txt-price">全国统一零售价:￥{{ documentPrice }}</div>
            </van-cell>
            <van-cell>
                <van-button round block color="#FE6601" @click="onCopy">复制资料名称和手机号码</van-button>
            </van-cell>
            <van-cell>
                <div>温馨提示：购买后请返回首页底部我的资料中阅读使用！</div>
            </van-cell>
        </van-cell-group>
        <van-cell-group title="商家联系方式：">
            <van-cell>
                <div>① 扫码添加好友，联系商家激活。</div>
            </van-cell>
            <van-cell>
                <van-image width="200" height="200" fit="contain" :src="proxyContactImg" />
            </van-cell>
            <van-cell>
                <div>② 商家联系方式如下：</div>
            </van-cell>
            <van-cell>
                <div>{{ proxyContactInfo }}</div>
            </van-cell>
        </van-cell-group>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
    },
    data() {
        return {
            userPhone: null,
            proxyContactInfo: null,
            documentGuid: null,
            documentName: null,
            documentPrice: null,
        };
    },
    computed: {
        proxyContactImg() {
            return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=1`;
        },
    },
    methods: {
        getRoutParams() {
            this.documentGuid = this.$route.query.documentGuid;
        },
        getUserInfo() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {};
            this.$axios
                .post(Config.user.info, model)
                .then((res) => {
                    this.userPhone = res.data.Data.UserPhone;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        getProxyInfo() {
            var model = {
                ProxyCode: this.$store.state.proxyCode,
            };
            this.$axios
                .post(Config.proxy.contactInfo, model)
                .then((res) => {
                    this.proxyContactInfo = res.data.Data.ContactInfo;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        getDocumentInfo() {
            var model = {
                DocumentGuid: this.documentGuid,
            };
            this.$axios
                .post(Config.document.info, model)
                .then((res) => {
                    this.documentName = res.data.Data.DocumentName;
                    this.documentPrice = res.data.Data.DocumentPrice.toFixed(2);
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        onCopy() {
            try {
                var that = this;
                var content = `购买信息\n资料名称：${this.documentName}\n手机号码：${this.userPhone}`;
                this.$copyText(content).then(
                    function (e) {
                        that.$toast.success("复制成功。");
                    },
                    function (e) {
                        that.$toast.fail("复制失败。" + e.message);
                    }
                );
            } catch (error) {
                this.$dialog({ title: "错误", message: error.message });
            }
        },
    },
    mounted() {
        this.getRoutParams();
        this.getUserInfo();
        this.getProxyInfo();
        this.getDocumentInfo();
    }
};
</script>
<style scoped>
.content-box {
    background-color: white;
}

.txt-price {
    color: #FE6601;
    font-weight: bold;
    font-size: 16px;
}
</style>